export default {
  SAVE_DRIVER_INFO(state, driverInfo) {
    state.driverInfo = driverInfo;
  },
  SET_COORDS(state, coords) {
    state.coords = coords;
  },
  CLEAR_DRIVER(state) {
    state.driverInfo = null;
  },
}