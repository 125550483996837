import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/LoginView'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView'),
  },
  {
    path: '/confirmation-code',
    name: 'confirmation-code',
    component: () => import('@/views/ConfirmationCodeView'),
  },
  
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/User/UserLayout.vue'),
    children: [
      {
        path: 'edit',
        name: 'user-edit',
        component: () => import('@/views/User/UserEdit')
      },
      {
        path: 'city/main',
        alias: '',
        name: 'user-city-main',
        component: () => import('@/views/User/City/CityMainView'),
      },

      {
        path: 'city/my-trips',
        name: 'user-city-my-trips',
        component: () => import('@/views/User/City/CityMyTripsView'),
      },

      {
        path: 'safety',
        name: 'user-safety',
        component: () => import('@/views/User/SafetyView'),
      },

      {
        path: 'safety/create',
        name: 'user-safety-create',
        component: () => import('@/views/User/SafetyCreateView'),
      },

      {
        path: 'intercity',
        name: 'user-intercity-layout',
        component: () => import('@/views/User/Intercity/IntercityLayout'),
        children: [
          {
            path: 'intercity-create-order',
            alias: '',
            name: 'user-intercity-create-order',
            component: () => import('@/views/User/Intercity/IntercityCreateOrderView'),
          },
          {
            path: 'intercity-ride-board',
            name: 'user-intercity-ride-board',
            component: () => import('@/views/User/Intercity/IntercityRideBoardView'),
            props: true
          },
          {
            path: 'intercity-my-orders',
            name: 'user-intercity-my-orders',
            component: () => import('@/views/User/Intercity/IntercityMyOrdersView'),
          },
        ]
      },
    ]
  },

  {
    path: '/driver',
    name: 'driver',
    component: () => import('@/views/Driver/DriverLayout.vue'),
    children: [
      {
        path: 'main',
        alias: '',
        name: 'driver-main',
        component: () => import('@/views/Driver/DriverMainView'),
      },
      {
        path: 'edit',
        name: 'driver-edit',
        component: () => import('@/views/Driver/DriverEdit')
      },
      {
        path: 'city/orders',
        name: 'driver-city-orders',
        component: () => import('@/views/Driver/DriverCityOrdersView')
      },
      
      {
        path: 'intercity',
        name: 'driver-intercity-layout',
        component: () => import('@/views/Driver/Intercity/IntercityLayout'),
        children: [
          {
            path: 'intercity-ride-board',
            alias: '',
            name: 'driver-intercity-ride-board',
            component: () => import('@/views/Driver/Intercity/IntercityRideBoardView'),
            props: true
          },
          {
            path: 'intercity-create-order',
            name: 'driver-intercity-create-order',
            component: () => import('@/views/Driver/Intercity/IntercityCreateOrderView'),
          },
          {
            path: 'intercity-my-orders',
            name: 'driver-intercity-my-orders',
            component: () => import('@/views/Driver/Intercity/IntercityMyOrdersView'),
          },
        ]
      },
    ]
  },


  {
    path: '/download-instructions',
    name: 'download-instructions',
    component: () => import('@/views/DownloadInstructions'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
