<template>
  <div class="notify-item">
    <div class="notify-item__title">
      <slot />
    </div>

    <div class="notify-item__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotifyItem',
}
</script>

<style lang="scss" scoped>
.notify-item {
  padding: 5px 8px;
  border-radius: 6px;
  box-shadow: 0 0 10px -1px rgba(128, 128, 128, 0.707);
  background-color: #fff;

  &__title {
    font-size: 16px;
    line-height: 115%;
    font-weight: 500;
    color: rgb(30, 30, 30);
  }
  
  &__actions {
    margin-left: auto;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>