var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.iss,{tag:"button",class:[
    'v-button',
    {'v-button_disabled': _vm.disabled },
    {'v-button_textStart': _vm.textStart},
    {'v-button_textDark': _vm.textDark},
    {'v-button_small': _vm.small},
    `v-button_${_vm.variant}`,
    `v-button_${_vm.iss}`,
  ],attrs:{"disabled":_vm.disabled}},[_vm._t("default"),(_vm.$slots['right-icon'])?_c('div',{staticClass:"v-button__right-icon"},[_vm._t("right-icon")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }